import MonitorImage from '@images/monitor.svg'
import PenImage from '@images/pen.svg'
import ZapImage from '@images/zap.svg'
import ZapImageActive from '@images/zap-active.svg'
import GiftImage from '@images/gift.svg'
import AwardImage from '@images/award.svg'

const SegmentedBarData = {
  projects: {
    index: 0,
    name: "Projects",
    icon: MonitorImage,
    backgroundColor: "#FFCC19",
    elementId: "projects",
  },
  services: {
    index: 1,
    name: "Services",
    icon: GiftImage,
    backgroundColor: "#F5717F",
    elementId: "services",
  },
  philosophy: {
    index: 2,
    name: "Philosophy",
    icon: PenImage,
    backgroundColor: "#FA6B1E",
    elementId: "philosophy",
  }, 
  testimonials: {
    index: 3,
    name: "Testimonials",
    icon: AwardImage,
    backgroundColor: "#706DE7",
    elementId: "testimonials"
  },
  // getStarted: {
    // index: 4,
  //   name: "Get Started",
  //   icon: ZapImage,
  //   activeIcon: ZapImageActive,
  //   left: "78%",
  //   idleForegroundColor: "#11D6DE",
  //   activeForegroundColor: "#ffffff",
  //   backgroundColor: "#11D6DE",
  //   elementId: "getStarted"
  // }
}

export default SegmentedBarData;