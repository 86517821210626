import React from 'react';
import {BrowserView, MobileView, isMobile} from 'react-device-detect';
import { motion } from 'framer-motion';

const BrowserDraggable = ({ children, ...rest }) => {

  if(!isMobile) {
      return (
        <motion.div drag {...rest}>
          {children}
        </motion.div>
      )
  } else {
    return (
      <div  {...rest}>
        {children}
      </div>
    )
  }
}
  
  export default BrowserDraggable