import React, { useState, useEffect, useRef, useCallback } from "react";
import { motion } from 'framer-motion';
import debounce from 'lodash.debounce'
import './styles.scss'

import SegmentedBarData from '@data/HomeSectionData.js';


const HomeSegmentedBar = ({ scroll, showSegmentedBar, currentSection }) => {
  const containerRef = useRef(null);
  const [selectedSection, setSelectedSection] = useState(SegmentedBarData.projects);
  const [manuallySelectedSection, setManuallySelectedSection] = useState(SegmentedBarData.projects);
  const [canUpdateSection, setCanUpdateSection] = useState(true);

  

  const segmentIdealWidth = useCallback(() => {
    if (!containerRef.current)  {
      return 0
    }

    const container = containerRef.current
    const width = container.clientWidth;
    const segmentWidth = (width - 48) / Object.keys(SegmentedBarData).length;
    return segmentWidth
  }, [containerRef]);

  useEffect(() => {
    if(canUpdateSection && currentSection && currentSection !== "") {
      setSelectedSection(SegmentedBarData[currentSection])
    }
  }, [currentSection, canUpdateSection]);

  useEffect(() => {
    if (manuallySelectedSection == currentSection) {
      setCanUpdateSection(true);
    }
  }, [currentSection, manuallySelectedSection]);

  function onSectionClick(section) {
    const data = SegmentedBarData[section];
    setSelectedSection(data);
    setManuallySelectedSection(section);
    setCanUpdateSection(false);
    if(scroll) {
      scroll.scrollTo("#" + data.elementId)
    }
  }

  function currentSectionTextColor(section) {
    const data = SegmentedBarData[section];
    if (data.name == selectedSection.name) {
      return selectedSection.activeForegroundColor || "white"
    } 
    return data.idleForegroundColor || "white"
  }

  function currentSectionIcon(section) {
    const data = SegmentedBarData[section];
    if (data.name == selectedSection.name) {
      return selectedSection.activeIcon || selectedSection.icon
    } 
    return data.icon
  }
  return (
    <motion.div
      initial="hidden"
      variants={segmentedBarAnimation}
      animate={showSegmentedBar ? "visible" : "hidden"}
      className="segmented-bar-container"
      ref={containerRef}
      >
      <div className="segmented-bar-track">
      <motion.div
        transition={{ duration: 0.2 }}
        className="segmented-bar-selector" 
        animate={{
          left: selectedSection.index * segmentIdealWidth(),
          backgroundColor: selectedSection.backgroundColor
        }}
        style={{ 
          width: segmentIdealWidth() + "px",
        }}
      />
      {
        Object.keys(SegmentedBarData).map((section) => (
          <div
            className="segmented-bar-section"
            onClick={() => onSectionClick(section)}
            >
            <motion.img 
              style={{ stroke: "white" }}
              src={currentSectionIcon(section)} 
              draggable={false}/>
            <motion.p
              animate={{
                color: currentSectionTextColor(section)
              }}
            >
              {SegmentedBarData[section].name}
            </motion.p>
          </div>
        ))
      }
      </div>
    </motion.div>
  )
}

const segmentedBarAnimation = {
  hidden: {
    top: "150%",
  },
  visible: {
    top: "86%",
  }
}

export default HomeSegmentedBar;