import React from 'react';

let initialState = {
  currentVideoId: "",
  openVideoModal: false,
  currentVisibleSection: '',
  isPageLoaded: false
};


const MainContext = React.createContext();

function mainReducer(state, action) {
  switch (action.type) {
    case 'play_video': {
      return { 
        ...state,
        currentVideoId: action.payload, 
        openVideoModal: true,
      }
    }
    case 'close_video': {
      return { 
        ...state, 
        openVideoModal: false
      }
    }
    case 'update_section': {
      return {
        ...state,
        currentVisibleSection: action.payload
      }
    }
    case 'update_page_loaded': {
      return {
        ...state,
        isPageLoaded: action.payload
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function MainContextProvider({children}) {
  const [state, dispatch] = React.useReducer(mainReducer, initialState)
  const value = {state, dispatch}
  return <MainContext.Provider value={value}>{children}</MainContext.Provider>
}

function useMainContext() {
  const context = React.useContext(MainContext)
  if (context === undefined) {
    throw new Error('useMainContext must be used within a MainContextProvider')
  }
  return context
}

function MainContextConsumer({children}) {
  return (
    <MainContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error('MainContext must be used within a MainContextConsumer')
        }
        return children(context)
      }}
    </MainContext.Consumer>
  )
}

export { MainContext, useMainContext, MainContextProvider, MainContextConsumer};