import React, { useEffect, useState, useRef } from 'react'
import { Link, navigate } from 'gatsby'
import { isBrowser, BrowserView, isMobileOnly } from 'react-device-detect';
import BrowserDraggable from '@components/BrowserDraggable';
import Cursor from '../Cursor'
import { motion } from 'framer-motion';
import '@styles/Layout.scss'
import classNames from 'classnames';
import openInNewTab from '../utils/openInNewTab';
import HomeSegmentedBar from '@components/Home/SegmentedBar';
import ModalVideo from 'react-modal-video'
import Drift from "react-driftjs";
import SegmentedBarData from '@data/HomeSectionData.js';

import Logo from '@images/logo.svg'
import InstagramImage from '@images/instagram.svg'
import LinkedInImage from '@images/linkedin.svg'
import FacebookImage from '@images/facebook.svg'
import TwitterImage from '@images/twitter.svg'
import ArrowUp from '@images/arrow-up.svg'

import { MainContextProvider, useMainContext } from './LayoutContext'

const initialLoadingTime = 1500
var scroll;

const Layout = (props) => {
  const { state, dispatch } = useMainContext()
  const { location, title, children, isHome } = props
  const containerRef = useRef(null);
  const [hasMounted, setHasMounted] = useState(false);
  const [showSegmentedBar, setShowSegmentedBar] = useState(false);
  const [currentSection, setCurrentSection] = useState('');
  const [isPageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    if (isPageLoaded) {
      setTimeout(() => {
        scroll?.start()
        
      }, 1500);
    }
  }, [isPageLoaded]);

  useEffect(() => {
    setHasMounted(true);
    setTimeout(() => {
      setPageLoaded(true);
      dispatch({ type: 'update_page_loaded', payload: true })
      if(props.setPageLoaded) {
        props.setPageLoaded(true);
      }
    }, initialLoadingTime);
  }, []);

  useEffect(() => {
    dispatch({ type: 'update_section', payload: currentSection })
  }, [currentSection])

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    import('locomotive-scroll').then((locomotiveModule)=> {
      scroll = new locomotiveModule.default({
        el: containerRef.current,
        smooth: true,
        smartphone: {
          smooth: true,
        },
        tablet: {
          smooth: true,
        }
      })

      if(isHome) {
        scroll.on('scroll', (args) => {
        const { limit, scroll } = args;
        // setScrollData({
        //   offset: scroll.y,
        //   limit: limit.y,
        // })

          let percentage = (scroll.y / limit.y) * 100
          setShowSegmentedBar(percentage > 4 && percentage < 96);
          checkAndSetSection(args)
          // if(scroll) {
            // scroll?.update()
          // }
        });
      }
      
      if(isHome && !isPageLoaded) {
        scroll.stop()
      } else {
        scroll.start()
      }

    })
    

  return () => {
    scroll?.destroy()
  }

  }, [location]);

  function checkAndSetSection(args) {

    function isCurrentSection(sectionId) {
      return typeof args.currentElements[sectionId] === 'object'
    }

    let projectId = SegmentedBarData.projects.elementId;
    let servicesId = SegmentedBarData.services.elementId;
    let philosophyId = SegmentedBarData.philosophy.elementId;
    let testimonialsId = SegmentedBarData.testimonials.elementId;
    // let getStartedId = SegmentedBarData.getStarted.elementId;
    if (isCurrentSection(projectId)) {
      setCurrentSection(projectId);
    } else if (isCurrentSection(servicesId)) {
      setCurrentSection(servicesId);
    }else if (isCurrentSection(philosophyId)) {
      setCurrentSection(philosophyId);
    }else if (isCurrentSection(testimonialsId)) {
      setCurrentSection(testimonialsId);
    }
    // }else if (isCurrentSection(getStartedId)) {
    //   setCurrentSection(getStartedId);
    // }
    
  }

  if (!hasMounted) {
    return null;
  }

  const rootDivClasses = classNames(
    "layout-root",
    "dotted-bg",
    {
      "hide-cursor": !isBrowser
    }
  )

  return (
    <>
      {props.seo}
      <BrowserView>
      { !state.openVideoModal &&
          <Cursor/>
      }
      </BrowserView>
      <div
        className={rootDivClasses}
        ref={containerRef}
        data-scroll-container
        data-scroll
      >
        <NavBar isHome={isHome} isPageLoaded={isPageLoaded}/>
          {children}
        <Footer/>
      </div>
      {
        isHome &&
          <HomeSegmentedBar 
            scroll={scroll}
            showSegmentedBar={showSegmentedBar}
            currentSection={currentSection}
          />
      }
      <ModalVideo 
        channel='youtube' 
        autoplay 
        isOpen={state.openVideoModal} 
        videoId={state.currentVideoId} 
        onClose={() => dispatch({type: 'close_video'})} 
        allowFullScreen
        autoplay
      />
      <Drift appId="eecayucdvvif" />
    </>
    )
}

const NavbarAnimationVariant = {
  initial: {
    scale: 2,
    left: isMobileOnly ? '45%' : '50%',
    top: '50vh',
    x: '-50%'
  },
  loaded: {
    scale: 1,
    left: '0%',
    top: '40px',
    x: '0%'
  }
}

const NavBar = ({ isHome, isPageLoaded }) => {
  return (
    <nav className="navbar">
      <Link className="logo-container" to="/">
        {
          isHome ?
          (
            <motion.div
                className="logo-wrapper"
                variants={NavbarAnimationVariant}
                transition={{ duration: 1 }}
                initial={"initial"}
                animate={isPageLoaded ? "loaded" : "initial"}
            >
              <img 
                className="navbar-logo" 
                src={Logo} 
                alt="logo"
              />
              <span  className="navbar-logo-name">
                Laagom
              </span>
            </motion.div>
          ) : (
            <>
            <img 
              className="navbar-logo" 
              src={Logo} 
              alt="logo"
            />
            <span className="navbar-logo-name">Laagom</span>
            </>
          )
        }
      </Link>

      <div className="navbar-links-container">
        {/* <BrowserDraggable>
          <div 
            className="nav-link"
            onClick={() => scroll?.scrollTo('#projects')}
            data-cursor-type="info"
            data-cursor-info="Scroll to Projects"
          >
            Projects
          </div>
        </BrowserDraggable> */}
        {
          isHome && <BrowserDraggable>
          <motion.div
            className="nav-get-started"
            transition={{ delay: 1 }}
            initial={{ opacity: 0 }}
            animate={{
              opacity: isPageLoaded ? 1 : 0
            }}
            onClick={() => scroll?.scrollTo("#" + SegmentedBarData.projects.elementId)}
          >
            Get Started
          </motion.div>
        </BrowserDraggable>
        }
      </div>
    </nav>
  )
}


const Footer = ()  => (
  <footer className="main-layout-footer-container">
    <div className="main-container">
      <div className="content-container">
      <BrowserDraggable>
        <motion.img 
          className="footer-logo" 
          src={Logo} 
          alt="logo" 
          draggable={false}
          onClick={() => navigate('/')}
        />
      </BrowserDraggable>
      <BrowserDraggable>
        <p className="quote"><span>Moderation</span> is key.</p>
      </BrowserDraggable>
      <BrowserDraggable>      
        <p
          className="email"
          data-cursor-type="info"
          data-cursor-info="Mail us!"
        >
          E: <a draggable={false} href="mailto:hello@laagom.com">hello@laagom.com</a>
        </p>
      </BrowserDraggable>

      <BrowserDraggable>
        <div
          className="back-to-top-button"
          onClick={() => scroll?.scrollTo("top")}
          data-cursor-type="info"
          data-cursor-info="Scroll to Top!"
        >
          <img
            src={ArrowUp} 
            alt="arrow up" 
            draggable={false}
          />
        </div>
      </BrowserDraggable>
    </div>
      <div className="social-container">
        {/* <BrowserDraggable>
          <img
            src={TwitterImage}
            alt="Twitter"
            draggable={false}
            onClick={() => openInNewTab('https://twitter.com/laagom1')}
            data-cursor-type="info"
            data-cursor-info="Open Twitter"
          />
        </BrowserDraggable> */}
        <BrowserDraggable>
          <img
            src={LinkedInImage}
            alt="LinkedIn"
            draggable={false}
            onClick={() => openInNewTab('https://www.linkedin.com/company/laagom/')}
            data-cursor-type="info"
            data-cursor-info="Open LinkedIn"
          />
        </BrowserDraggable>
        <BrowserDraggable>
          <img
            src={InstagramImage}
            alt="Instagram"
            draggable={false}
            onClick={() => openInNewTab('https://www.linkedin.com/company/laagom/')}
            data-cursor-type="info"
            data-cursor-info="Open Instagram"
          />
        </BrowserDraggable>
      </div>
    </div>
    <div className="copyright-container">
      <BrowserDraggable>
        <p>© 2021 Laagom.  All Rights Reserved.</p>
      </BrowserDraggable>
    </div>
  </footer>
)


const LayoutWithContext = ({ ...rest }) => {
  return (
    <MainContextProvider>
      <Layout {...rest} />
    </MainContextProvider>
  )
}

export default LayoutWithContext
